import React from "react"

import Layout from "../components/layout"

const About = () => {
    return (
        <Layout>
            <div className="container mx-auto px-16 py-12">
                <h1 className="text-center text-4xl font-normal orangeText">Contact Page</h1>
            </div>
        </Layout>
    )
}

export default About
